import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from "@angular/forms";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { ProjectCardComponent } from "./project-card/project-card.component";

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
    ],
    declarations: [
        HeaderComponent,
        FooterComponent,
        ProjectCardComponent
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        ProjectCardComponent
    ]
})
export class ComponentsModule { }