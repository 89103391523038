import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(
    private platform: Platform
  ) {

  }

  isIos() {
    return this.platform.is("ios");
  }
}
